<template>
  <router-link
    :to="readMoreLink"
    :class="{ 'ai-service': true, [service]: service, 'disabled': comingSoon }"
  >
    <img
      class="ai-service__icon"
      :src="src"
      :alt="title"
    >
    <div class="ai-service__content grid">
      <span class="ai-service__content__title">{{ title }}</span>
      <p
        v-if="comingSoon"
        class="ai-service__content__coming-soon"
      >
        ({{ $t('services.comingSoon') }})
      </p>
    </div>
  </router-link>
</template>

<script>

export default {
  props: {
    service: {
      type: String,
      required: true,
      validator: (value) => ['nlp', 'vca', 'aiu', 'asa', 'kg', 'mt', 'med'].indexOf(value) !== -1,
    },
    src: {
      type: String,
      required: true,
    },
    comingSoon: {
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    readMoreLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-size: 0.96rem;

.ai-service {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0 10px 18px rgba(235, 237, 244, 0.76);
  background-color: white;

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 1.12rem;
    height: 1.08rem;
    border-top-left-radius: 8px;
    background-color: black;
  }

  &.nlp::before {
    background: #FC9C0D20;
  }

  &.vca::before {
    background: #D9CAFF53;
  }

  &.aiu::before {
    background: #DCEEFF;
  }

  &.asa::before {
    background: #D8F8F2;
  }

  &.kg::before {
    background: #FFEFEC;
  }

  &.mt::before {
    background: rgba(66, 121, 196, 0.15);
  }

  &.med::before {
    background: #FBDEEF;
  }

  &__icon {
    width: $icon-size;
    height: $icon-size;
    margin-top: 0.18rem;
    margin-left: 0.26rem;
    margin-bottom: 0.18rem;
    transition: .3s ease-in-out;
    background: transparent;
  }

  &__content {
    gap: 0.08rem;
    padding: 0.2rem;

    &__title {
      font-size: 0.2rem;
      font-weight: 800;
      color: $text-primary;
      display: inline-flex;
      align-items: center;
    }

    &__coming-soon {
      font-weight: 700;
      font-size: .18rem;
      line-height: .24rem;
      color: $text-primary;
    }

    &__subtitle {
      font-size: 0.14rem;
      color: $text-tertiary;
    }
  }

  &:hover {
    .ai-service-icon-container {
      img {
        transform: translateX(-0.1rem) translateY(-0.1rem) rotate(-11deg);
        box-shadow: 0 0.15rem 0.06rem rgba(90, 90, 90, 0.1);
      }
    }
  }
}
</style>
