<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="service-list">
    <h1
      v-if="title"
      class="service-list__title font-800"
    >
      {{ title }}
    </h1>
    <p
      v-if="subtitle"
      class="service-list__subtitle"
      v-html="subtitle"
    />
    <div class="service-list__cards grid grid-cols-1">
      <template v-for="(item, index) in servicesCards">
        <service-card
          v-if="item.readMoreLink !== $route.path"
          :key="index"
          v-bind="item"
          :coming-soon="item.isComingSoon"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ImageNLP from '@/assets/images/icons/nav/NLP.svg';
import ImageAIU from '@/assets/images/icons/nav/AIU.svg';
import ImageASA from '@/assets/images/icons/nav/ASA.svg';
import ImageVCA from '@/assets/images/icons/nav/VCA.svg';
import ImageKG from '@/assets/images/icons/nav/KG.svg';
import ImageMT from '@/assets/images/icons/nav/MT.svg';
import ServiceCard from '@/views/components/ServiceCard/Index.vue';

export default {
  components: { ServiceCard },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      servicesCards: [
        {
          src: ImageMT,
          service: 'mt',
          title: this.$t('title[\'Machine Translation\']'),
          description: this.$t('description.serviceCardMT'),
          readMoreLink: '/services/mt',
        },
        {
          src: ImageNLP,
          service: 'nlp',
          title: this.$t('title[\'Natural Language Processing\']'),
          description: this.$t('description[\'Empowering your advanced NLP based applications with simple API calls.\']'),
          readMoreLink: '/services/nlp/document-classification',
          isComingSoon: false,
        },
        {
          src: ImageAIU,
          service: 'aiu',
          title: this.$t('title[\'Automatic Image Understanding\']'),
          description: this.$t('description[\'Giving the ability of processing massive images into your able hands.\']'),
          readMoreLink: '/services/aiu/image-tagging',
          isComingSoon: false,
        },
        {
          src: ImageASA,
          service: 'asa',
          title: this.$t('title[\'Audio and Speech Analysis\']'),
          description: this.$t('description[\'Enabling your application both can hear and speak, as smart as it should be.\']'),
          readMoreLink: '/services/asa/automatic-speech-recognition',
          isComingSoon: false,
        },
        {
          src: ImageVCA,
          service: 'vca',
          title: this.$t('title[\'Video Content Analysis\']'),
          description: this.$t('description[\'Unlocking video content analysis capabilities based on cutting-edge deep learning models.\']'),
          readMoreLink: '/services/vca/video-classification',
          isComingSoon: false,
        },
        {
          src: ImageKG,
          service: 'kg',
          title: this.$t('title[\'Knowledge Graph\']'),
          description: this.$t('description[\'Transforming myriads of information into structured graphs of knowledge.\']'),
          readMoreLink: '/services/kg',
          isComingSoon: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.service-list {
  &__title {
    margin-bottom: 0.14rem;
  }

  &__subtitle {
    margin-bottom: 0.32rem;
  }

  &__cards {
    gap: 0.32rem;

    @include lg {
      @include grid-cols-2;
    }
  }
}
</style>
