<template>
  <div
    class="why"
    :class="{ [introduction]: introduction }"
  >
    <div class="why-information">
      <span class="why-heading">{{ $t(`title['Why choose us?']`) }}</span>
      <span class="why-description">{{ $t(`description['We are the most comprehensive']`) }}</span>
    </div>
    <login-button :variant="loginVariant" />
  </div>
</template>

<script>
import LoginButton, { variants } from '@/views/components/LoginButton/Index.vue';

export default {
  components: { LoginButton },
  props: {
    loginVariant: {
      type: String,
      default: 'default',
      validator: (value) => variants.indexOf(value) !== -1,
    },
    introduction: {
      type: String,
      default: 'default',
      validator: (value) => [
        'default',
        'services_nlp_intro',
        'services_vca_intro',
        'services_aiu_intro',
        'services_asa_intro',
      ].indexOf(value) !== -1,
    },
  },
  methods: {
    onDemoClick() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
$nlp: linear-gradient(276.75deg, #342001 1.11%, #4B2F01 85.2%);
$vca: linear-gradient(276.75deg, #521FD1 1.11%, #6C23F5 85.2%);
$aiu: linear-gradient(97.41deg, #013CD2 4.08%, #448EFE 104.02%);
$asa: linear-gradient(95.95deg, #0F9D84 16.22%, #13C4A5 99.85%);

.why {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 1.64rem;
  width: 100%;
  padding: 0.3rem 0.85rem;
  color: white;

  &.default {
    background: linear-gradient(276.75deg, #1B134C 1.11%, #1B134C 85.2%);
  }

  &.services {
    &_nlp_intro {
      background: $nlp;
    }
    &_vca_intro {
      background: $vca;
    }
    &_aiu_intro {
      background: $aiu;
    }
    &_asa_intro {
      background: $asa;
    }
  }

  &::before {
    content: '';
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(~@/assets/images/background/data-lines.svg) 100% center no-repeat;
  }

  .why-information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;

    .why-heading {
      font-size: 0.36rem;
      font-weight: 800;
    }

    .why-description {
      margin-top: 0.12rem;
      font-size: 0.14rem;
      font-weight: 700;
    }
  }

  @include mobile {
    justify-content: flex-start;
    align-items: flex-start;

    .why-information {
      flex: 1;
      margin: 0 0.24rem 0.24rem 0;
    }

    &::before {
      top: 0;
      background-size: 100%;
    }
  }
}
</style>
