<template>
  <div class="advantages">
    <h2 class="advantages__title">
      {{ $t(`title['What Can You Get with Rodin?']`) }}
    </h2>
    <div class="advantages__cards grid grid-cols-1">
      <advantage
        v-for="(item, index) in advantages"
        :key="index"
        v-bind="item"
      />
    </div>
  </div>
</template>

<script>
import ImageBrain from '@/assets/images/icons/brain-api.svg';
import ImageDeployment from '@/assets/images/icons/deployment.svg';
import ImageMarketplace from '@/assets/images/icons/marketplace.svg';
import ImagePipeline from '@/assets/images/icons/pipeline.svg';
import Advantage from './Advantage.vue';

export default {
  components: { Advantage },
  data() {
    return {
      advantages: [{
        icon: ImageBrain,
        title: this.$t('title[\'AI Cognitive Services\']'),
        subtitle: this.$t('advantage[\'20+ ready-to-go AI\']'),
      }, {
        icon: ImagePipeline,
        title: this.$t('title[\'AI Customized Pipeline Builder\']'),
        subtitle: this.$t('advantage[\'We support the integration\']'),
        isComingSoon: false,
      }, {
        icon: ImageDeployment,
        title: this.$t('title[\'Customized Deployment\']'),
        subtitle: this.$t('advantage[\'Users could fast-deploy their\']'),
      }, {
        icon: ImageMarketplace,
        title: this.$t('title[\'AI Vertical Solutions\']'),
        subtitle: this.$t('advantage[\'Rodin also serve multiple verticals\']'),
      }],
    };
  },
};
</script>

<style lang="scss" scoped>
$gap: 0.56rem;

.advantages {
  background: url(~@/assets/images/background/diagonal-box.svg) no-repeat top right;

  &__title {
    font-weight: 800;
    font-size: 0.32rem;
    line-height: 0.44rem;
    color: $text-primary;
  }

  &__cards {
    margin-top: 0.62rem;
    gap: $gap;

    :last-child::after {
      width: 0;
    }

    > * {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: $gap / 2;
        right: -$gap / 2;
        width: 1px;
        height: calc(100% - (#{$gap} / 2));
        background: #D9CAFF;
      }
    }

    @include lg {
      @include grid-cols-4;
    }

    @include tablet {
      @include grid-cols-2;

      :nth-child(2n)::after {
        width: 0;
      }
    }

    @include mobile {
      > ::after {
        width: 0;
      }
    }
  }
}
</style>
