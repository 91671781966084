<template>
  <button
    v-if="!isAuthenticated()"
    class="btn-action login hvr-grow-shadow"
    :class="$props.variant"
    @click="onClick"
  >
    {{ $t('button.login') }}
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export const variants = [
  'healthcare-banner',
  'education-banner',
  'default',
  'header',
  'footer',
  'services_nlp_intro-banner',
  'services_vca_intro-banner',
  'services_aiu_intro-banner',
  'services_asa_intro-banner',
  'services_nlp_intro-why',
  'services_vca_intro-why',
  'services_aiu_intro-why',
  'services_asa_intro-why',
];

export default {
  name: 'LoginButton',
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => variants.indexOf(value) !== -1,
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    onClick() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
$healthcare-banner: linear-gradient(96.63deg, #56CCDB 6.77%, rgba(86, 204, 219, 0.49) 160.74%);
$education-banner: linear-gradient(96.63deg, #E354FA 6.77%, #691EE7 160.74%);
$nlp-banner: linear-gradient(272.9deg, #FC9C0D -39.62%, #C97A04 98.83%);
$vca-banner: linear-gradient(274.8deg, #D9CAFF -76.65%, #521FD1 96.07%);
$aiu-banner: linear-gradient(274.8deg, #2DCBFD -76.65%, #3A71FF 96.07%);
$asa-banner: linear-gradient(274.8deg, #14D4B2 -76.65%, #14D4B2 96.07%);
$nlp-why: linear-gradient(100.62deg, #E9900A 48.27%, #CC7C05 203.77%);
$vca-why: linear-gradient(272.91deg, #D9CAFF -6.06%, #521FD1 116.51%);
$aiu-why: linear-gradient(96.66deg, #4791FF 11.66%, #0D4EF5 159.3%);
$asa-why: linear-gradient(275.05deg, #14D4B2 -59.14%, #17F1CB 85.99%);

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 2rem;

  &.default {
    border: 1px solid;
    background: transparent;
  }

  &.header {
    background: $background-primary;
  }

  &.footer {
    border: 1px solid transparent;
    background: $background-footer;
    background-image: linear-gradient($background-footer, $background-footer), $button-footer-gradient;
    // .border-gradient doesn't seem to work - hence, explicitly defining properties
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  &.healthcare-banner {
    background: $healthcare-banner;
  }

  &.education-banner {
    background: $education-banner;
  }

  &.services_nlp_intro-banner {
    background: $nlp-banner;
  }

  &.services_vca_intro-banner {
    background: $vca-banner;
  }

  &.services_aiu_intro-banner {
    background: $aiu-banner;
  }

  &.services_asa_intro-banner {
    background: $asa-banner;
  }

  &.services_nlp_intro-why {
    background: $nlp-why;
  }

  &.services_vca_intro-why {
    background: $vca-why;
  }

  &.services_aiu_intro-why {
    background: $aiu-why;
  }

  &.services_asa_intro-why {
    background: $asa-why;
  }

}
</style>
