import { render, staticRenderFns } from "./OurVision.vue?vue&type=template&id=6aded651&scoped=true&"
var script = {}
import style0 from "./OurVision.vue?vue&type=style&index=0&id=6aded651&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aded651",
  null
  
)

export default component.exports