<template>
  <div class="advantage flex flex-col">
    <img
      class="advantage__icon"
      :src="icon"
      :alt="title"
    >
    <h6 class="advantage__title">
      {{ title }}
      <span
        v-if="isComingSoon"
        class="advantage__coming-soon"
      >
        ({{ $t('services.comingSoon') }}!)
      </span>
    </h6>
    <p class="advantage__subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    isComingSoon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-size: 0.42rem;
$padding: 0.08rem;

.advantage {
  position: relative;

  &__coming-soon {
    font-size: 0.8em;
    color: #999;
  }

  &__icon {
    width: $icon-size;
    height: $icon-size;
  }

  &__title {
    margin-top: 0.1rem;
    font-weight: 700;
    font-size: 0.16rem;
    line-height: 0.22rem;
    color: $text-primary;
  }

  &__subtitle {
    margin-top: 0.16rem;
    font-size: 0.14rem;
    line-height: 0.24rem;
    color: $text-tertiary;
    text-align: justify;
  }
}
</style>
