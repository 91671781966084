<template>
  <div id="home-page">
    <div class="panel rodin">
      <div class="content">
        <div class="labels-container">
          <span class="all-stack">{{ $t(`title['all-stack ai service platform']`) }}</span>
          <span class="empowering">{{ $t(`title['empowering intelligent apps with our customizable AI technology']`) }}</span>
        </div>
        <div class="rodin-actions">
          <button
            class="btn-action check hvr-grow-shadow"
            @click="onDemoClick"
          >
            {{ $t(`button['check our services']`) }}
          </button>
        </div>
        <div class="rodin-stats">
          <div class="stat">
            <span class="count">30+</span>
            <span class="count-description">{{ $t(`description['AI Services Covering']`) }}</span>
          </div>
          <div class="stat">
            <span class="count">6</span>
            <span class="count-description">{{ $t(`description['Major AI Domains']`) }}</span>
          </div>
        </div>
      </div>
    </div>
    <our-vision />
    <why-choose-us
      class="why-choose-us"
      login-variant="header"
    />
    <div class="panel-third">
      <what-can-you-get class="what-can-you-get" />
      <services-list
        class="ai-services"
        :title="$t(`title['Our AI Services']`)"
      />
    </div>
  </div>
</template>
<script>
import WhyChooseUs from '@/views/components/WhyChooseUs/Index.vue';
import ServicesList from '@/views/components/ServicesList/Index.vue';
import WhatCanYouGet from './WhatCanYouGet.vue';
import OurVision from './OurVision.vue';

export default {
  components: {
    WhyChooseUs,
    ServicesList,
    WhatCanYouGet,
    OurVision,
  },
  methods: {
    onDemoClick() {
      this.$router.push({ name: 'services_mt' });
    },
  },
};
</script>
<style lang="scss" scoped>
$panel-padding: 0.87rem;

@mixin hover-background {
  position: relative;
  &:hover {
    color: #FFF;
    border-color: #3A71FF;
    &::before {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    transition: 0.5s;
    border-radius: 50px;
    background-color: #3A71FF;
    z-index: -1;
  }
}

#home-page {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  background: #F7F8FF;
  color: $text-primary;

  .panel {
    display: flex;
    position: relative;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 0.85rem;
  }

  .rodin {
    position: relative;
    padding-bottom: 45%;
    height: 7.46rem;
    background: url('~@/assets/images/background/home_banner_v1.png') center/cover no-repeat;;
    color: white;

    .banner-image {
      position: absolute;
      right: 0;

      @include mobile {
        width: 100%;
        top: -2rem;
        right: -0.32rem;
        opacity: 0.6;
      }
    }

    & > .content {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);

      .labels-container {
        z-index: 1;
        display: flex;
        flex-direction: column;

        .perception {
          text-transform: uppercase;
          font-weight: 400;
          font-size: 0.24rem;
          line-height: 0.30rem;
        }

        .all-stack {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 0.36rem;
        }

        .empowering {
          margin-top: 0.12rem;
          font-size: 0.18rem;
          font-weight: 400;
        }
      }

      .rodin-actions {
        z-index: 1;
        display: flex;
        margin-top: 0.64rem;

        .check {
          margin-left: 0.16rem;
          text-transform: capitalize;
          background: linear-gradient(100.62deg, #3A71FF 48.27%, rgba(121, 58, 255, 0) 203.77%);
          border-radius: 6px;
          box-sizing: border-box;
          @include hover-background;
        }
      }
    }

    #particles-js {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .rodin-stats {
      display: flex;
      margin-top: 0.75rem;

      .stat {
        display: flex;
        flex-direction: column;
        border-left: 1px solid white;
        padding-left: 0.2rem;
        margin-right: 0.24rem;

        .count {
          font-size: 0.32rem;
          font-weight: 700;
        }

        .count-description {
          font-size: 0.20rem;
          font-weight: 300;
        }
      }
    }
  }

  .why-choose-us {
    margin-top: 0.64rem;
  }

  .panel-third {
    padding: 0 $panel-padding;

    .what-can-you-get {
      margin-top: 0.7rem;
    }

    .ai-services {
      margin-top: 0.53rem;
      margin-bottom: 0.9rem;
    }
  }
}

</style>
